var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/index.tsx
import { useEffect } from "react";
var useFormPersist = (name, {
  storage,
  watch,
  setValue,
  exclude = [],
  onDataRestored,
  validate = false,
  dirty = false,
  onTimeout,
  timeout
}) => {
  const watchedValues = watch();
  const getStorage = () => storage || window.sessionStorage;
  const clearStorage = () => getStorage().removeItem(name);
  useEffect(() => {
    const str = getStorage().getItem(name);
    if (str) {
      const _a = JSON.parse(str), { _timestamp = null } = _a, values = __objRest(_a, ["_timestamp"]);
      const dataRestored = {};
      const currTimestamp = Date.now();
      if (timeout && currTimestamp - _timestamp > timeout) {
        onTimeout && onTimeout();
        clearStorage();
        return;
      }
      Object.keys(values).forEach((key) => {
        const shouldSet = !exclude.includes(key);
        if (shouldSet) {
          dataRestored[key] = values[key];
          setValue(key, values[key], {
            shouldValidate: validate,
            shouldDirty: dirty
          });
        }
      });
      if (onDataRestored) {
        onDataRestored(dataRestored);
      }
    }
  }, [
    storage,
    name,
    onDataRestored,
    setValue
  ]);
  useEffect(() => {
    const values = exclude.length ? Object.entries(watchedValues).filter(([key]) => !exclude.includes(key)).reduce((obj, [key, val]) => Object.assign(obj, { [key]: val }), {}) : Object.assign({}, watchedValues);
    if (Object.entries(values).length) {
      if (timeout !== void 0) {
        values._timestamp = Date.now();
      }
      getStorage().setItem(name, JSON.stringify(values));
    }
  }, [watchedValues, timeout]);
  return {
    clear: () => getStorage().removeItem(name)
  };
};
var src_default = useFormPersist;
export {
  src_default as default
};
